<template>
  <th-page-wrapper
    :actions="actions"
    @save="handleSubmit"
    @close-requested="$router.push({ name: 'resources-devices' })"
  >
    <device-form ref="form" class="m-8" />
  </th-page-wrapper>
</template>

<script>
import DeviceForm from './components/form.vue'

export default {
  components: {
    DeviceForm
  },

  metaInfo() {
    return {
      title: this.$t('common.resource.device.singular')
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },

    actions() {
      return {
        permissionPrefix: 'resources:devices',
        returnedPath: { name: 'resources-devices' },
        excludeButtons: ['delete'],
        isNew: this.isNew
      }
    }
  },

  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    }
  }
}
</script>
