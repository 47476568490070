<template>
  <div class="content-row mb-2 flex h-48 flex-col">
    <!-- Name -->
    <div class="flex items-center">
      <div class="mr-2 text-th-primary">#{{ index + 1 }}</div>
      <search-select
        :computed-fields="['name']"
        :resource-query="{ query: { deleted: false } }"
        :model-value="modelValue.id"
        :list="options"
        class="w-full"
        @update:modelValue="(v) => $emit('update:modelValue', v)"
      />
    </div>

    <!-- Preview -->
    <div
      class="content-preview mt-2 flex-grow w-full overflow-hidden rounded-th-normal"
    >
      <img
        v-if="modelValue.type === 'image'"
        :src="modelValue.payload"
        class="h-full w-full object-cover"
      />
      <video
        v-if="modelValue.type === 'video'"
        autoplay
        muted
        :src="modelValue.payload"
        class="h-full w-full object-cover"
      />
    </div>
  </div>
</template>

<script>
import SearchSelect from '@/components/select/search'

export default {
  components: {
    SearchSelect
  },

  props: {
    modelValue: {
      type: Object,
      default: undefined,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  }
}
</script>
