<template>
  <div v-loading="loading">
    <!-- Locations -->
    <div
      v-for="location in avaialableContentLocations"
      :key="location"
      class="mb-2"
    >
      <!-- Add content -->
      <div class="w-56 mb-2">
        <el-form-item
          :label="translation[location]"
          :prop="`${location}-add-content`"
        >
          <search-select
            :id="`${location}-add-content`"
            :key="loading"
            :computed-fields="['name']"
            :model-value="null"
            :list="contents"
            :placeholder="
              $t(
                'pages.devices.edit.form.properties.content_templates.add_content.placeholder'
              )
            "
            @update:modelValue="(v) => handleFormInput(v, location)"
          />
        </el-form-item>
      </div>

      <!-- Contents -->
      <el-row :gutter="20">
        <el-col
          v-for="(item, index) in modelValue[location] || []"
          :key="index"
          :xl="4"
          :lg="6"
          :md="8"
          :sm="8"
        >
          <contents-row
            :key="loading"
            :model-value="rowContents(item)"
            :index="index"
            :options="contents"
            @update:modelValue="(v) => handleFormInput(v, location, index)"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ContentsRow from './contents-row'
import SearchSelect from '@/components/select/search'

export default {
  components: {
    ContentsRow,
    SearchSelect
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      contents: [],
      avaialableContentLocations: [
        'idle',
        'welcome',
        'cart',
        'payment',
        'payment_terminal',
        'payment_approved',
        'goodbye',
        'logo'
      ]
    }
  },

  computed: {
    rowContents() {
      return (item) => ({
        ...(this.contents.find((c) => c.id === item) || { id: item })
      })
    },
    translation() {
      return {
        cart: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.cart.label'
        ),
        goodbye: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.goodbye.label'
        ),
        idle: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.idle.label'
        ),
        logo: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.logo.label'
        ),
        payment: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.payment.label'
        ),
        payment_approved: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.payment_approved.label'
        ),
        payment_terminal: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.payment_terminal.label'
        ),
        welcome: this.$t(
          'pages.devices.edit.form.properties.type.contents.locations.welcome.label'
        )
      }
    }
  },

  created() {
    this.fetchResources()
  },

  methods: {
    async fetchResources() {
      try {
        this.loading = true
        const { contents } = await this.$resourceFetch('contents')
        this.contents = contents
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: err.message
        })
      } finally {
        this.loading = false
      }
    },

    handleFormInput(v, path, index) {
      if (Number.isFinite(index)) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          [path]: this.modelValue[path]
            .map((contentItem, contentIndex) => {
              if (contentIndex === index) return v
              return contentItem
            })
            .filter((item) => item)
        })
        return
      }

      this.$emit('update:modelValue', {
        ...this.modelValue,
        [path]: [...(this.modelValue[path] || []), v].filter((item) => item)
      })
    }
  }
}
</script>
