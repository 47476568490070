<template>
  <el-form ref="form" :model="form" :rules="rules">
    <th-wrapper
      :title="$t('common.titles.general_info.title')"
      body-class="p-8 pb-3"
    >
      <el-row :gutter="20">
        <!-- Name -->
        <el-col :sm="12" :md="12" :lg="8" :xl="4">
          <el-form-item
            :label="$t('pages.devices.edit.form.properties.name.label')"
            prop="name"
          >
            <el-input id="name" v-model="form.name" />
          </el-form-item>
        </el-col>

        <!-- Register -->
        <el-col :sm="12" :md="12" :lg="8" :xl="4">
          <el-form-item
            :label="$t('pages.devices.edit.form.properties.register.label')"
            prop="register"
          >
            <remote-search-select
              id="register"
              v-model="form.register"
              resource="registers"
              :modify-query="registersSearchQuery"
              :computed-fields="['register_number', 'name']"
            />
          </el-form-item>
        </el-col>

        <!-- Device ID -->
        <el-col :sm="12" :md="12" :lg="8" :xl="4">
          <el-form-item
            :label="$t('pages.devices.edit.form.properties.device_id.label')"
            prop="device_id"
          >
            <el-input id="device_id" v-model="form.device_id" disabled />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-if="form.device_configuration" :gutter="20">
        <!-- Protocol -->
        <el-col :sm="8" :md="8" :lg="4" :xl="2">
          <el-form-item
            :label="
              $t(
                'pages.devices.edit.form.properties.device_configuration.network.protocol.label'
              )
            "
            prop="protocol"
          >
            <el-input
              id="protocol"
              v-model="networkProtocol"
              placeholder="https"
              :disabled="!isLinked"
            />
          </el-form-item>
        </el-col>

        <!-- IP -->
        <el-col :sm="8" :md="8" :lg="4" :xl="2">
          <el-form-item
            :label="
              $t(
                'pages.devices.edit.form.properties.device_configuration.network.ip.label'
              )
            "
            prop="ip"
          >
            <el-input
              id="ip"
              v-model="networkIp"
              placeholder="xxx.xxx.xxx.xxx"
              :disabled="!isLinked"
            />
          </el-form-item>
        </el-col>

        <!-- Port -->
        <el-col :sm="8" :md="8" :lg="4" :xl="2">
          <el-form-item
            :label="
              $t(
                'pages.devices.edit.form.properties.device_configuration.network.port.label'
              )
            "
            prop="port"
          >
            <el-input
              id="port"
              v-model="networkPort"
              placeholder="xxxx"
              :disabled="!isLinked"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <!-- Template -->
      <el-form-item
        v-if="form.type === 'cfd'"
        :label="$t('pages.devices.edit.form.properties.template.label')"
        prop="template"
      >
        <el-radio-group v-model="form.template">
          <el-radio-button label="simple">
            {{ $t('pages.devices.edit.form.properties.template.simple') }}
          </el-radio-button>
          <el-radio-button label="advanced">
            {{ $t('pages.devices.edit.form.properties.template.advanced') }}
          </el-radio-button>
          <el-radio-button label="custom">
            {{ $t('pages.devices.edit.form.properties.template.custom') }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>

      <el-row :gutter="20">
        <el-col :md="12" :lg="12" :xl="8">
          <!-- Runtime URL -->
          <el-form-item
            v-if="form.template === 'custom'"
            :label="$t('pages.devices.edit.form.properties.runtime_url.label')"
            prop="template_config.runtime_url"
          >
            <th-image-upload-v2
              v-model="form.template_config"
              allow-video
              :info-msg="
                $t('pages.devices.edit.form.properties.runtime_url.info')
              "
              resource="contentImage"
              image-key="runtime_url"
              height="130px"
              :file-size-k-b-video="10000"
              fill-type="height"
              @update:modelValue="handleUploaded"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </th-wrapper>

    <!-- CFD - Customer facing display -->
    <th-wrapper
      v-if="form.type === 'cfd' && form.template === 'advanced'"
      :title="$t('pages.devices.edit.form.properties.content.header')"
      body-class="p-8 pb-4"
    >
      <!-- Content templates -->
      <el-form-item
        :label="
          $t('pages.devices.edit.form.properties.content_templates.label')
        "
        prop="content_templates"
      >
        <remote-search-select
          id="content_templates"
          v-model="contentTemplate"
          resource="contentTemplates"
          class="w-56"
          @resource-set="setContentTemplate"
        />
      </el-form-item>

      <hr class="mb-5" />

      <!-- Contents -->
      <contents v-model="form.contents" />
    </th-wrapper>
  </el-form>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import Contents from './contents'
import RemoteSearchSelect from '@/components/select/remote-search'
import { makeHandleableBody } from '@/utils/objects'

function genInitialData() {
  return {
    name: null,
    register: null,
    device_configuration: {},
    type: null,
    contents: {},
    template: null,
    template_config: {},
    device_id: null
  }
}

export default {
  components: {
    Contents,
    RemoteSearchSelect
  },

  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: false,
      valid: false,
      attributes: [],
      form: genInitialData(),
      payload: {},
      rules: {
        name: [
          {
            required: true,
            message: this.$t(
              'pages.devices.edit.form.validation.name.required'
            ),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('pages.devices.edit.form.validation.name.length'),
            trigger: 'blur'
          }
        ]
      },
      contentTemplate: null
    }
  },

  computed: {
    isNew() {
      return !this.$route.params.id
    },

    deviceId() {
      return this.$route.params.id
    },

    allowedTypes() {
      return [
        {
          value: 'cfd',
          label: this.$t('pages.devices.edit.form.properties.type.cfd.label')
        },
        {
          value: 'printer',
          label: this.$t(
            'pages.devices.edit.form.properties.type.printer.label'
          )
        }
      ]
    },

    networkIp: {
      get() {
        return safeGet(this.form, 'device_configuration.network.ip')
      },
      set(v) {
        this.updateNetworkConfig('ip', v)
      }
    },

    networkProtocol: {
      get() {
        return safeGet(this.form, 'device_configuration.network.protocol')
      },
      set(v) {
        this.updateNetworkConfig('protocol', v)
      }
    },

    networkPort: {
      get() {
        return safeGet(this.form, 'device_configuration.network.port')
      },
      set(v) {
        this.updateNetworkConfig('port', v)
      }
    },

    runtime_url: {
      get() {
        return safeGet(this.form, 'template_config.runtime_url')
      },
      set(v) {
        // need the object to exist so I can put runtime_url into it
        if (!this.form.template_config) this.form.template_config = {}

        // setting reactively, so el-input gets updated
        this.form.template_config.runtime_url = v
      }
    },

    isLinked() {
      return this.payload.status === 'bound'
    }
  },

  watch: {
    visible: function (newValue, oldValue) {
      if (oldValue && !newValue) this.resetData()
      else if (!newValue) this.resetData()
      if (newValue && !this.isNew) this.fetch(this.deviceId)
    },

    contentTemplate: function (value) {
      if (!value) this.form.contents = {}
    }
  },

  async mounted() {
    if (!this.isNew) this.fetch(this.deviceId)
  },

  methods: {
    registersSearchQuery(q, handler) {
      if (handler === 'search') return q
      return {
        query: {
          deleted: false,
          q
        }
      }
    },
    updateNetworkConfig(prop, v) {
      const deviceConfig = { ...this.form.device_configuration }
      safeSet(deviceConfig, `network.${prop}`, v)
      this.form.device_configuration = deviceConfig
    },
    async fetch(id) {
      try {
        this.loading = true
        const { data = {} } = await th.devices().get(id)
        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.device.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    handleItem(item) {
      this.payload = { ...item }
      if (!item.device_configuration) item.device_configuration = {}
      if (!item.contents) item.contents = {}
      this.form = {
        ...pick(item, Object.keys(this.form))
      }
    },

    async submitForm() {
      const valid = await this.validate()
      if (!valid) {
        return this.$message({
          type: 'warning',
          message: this.$t('common.forms.message.invalid_inputs')
        })
      }

      if (this.isNew) return this.create()
      this.alter(this.payload.id)
    },

    async validate() {
      return new Promise((resolve) => {
        this.$refs['form'].validate(resolve)
      })
    },

    async alter() {
      const payload = {
        ...this.payload,
        ...this.form
      }

      try {
        this.loading = true
        const { data = {} } = await th
          .devices()
          .patch(payload.id, makeHandleableBody(payload, this.form))

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.update.single', {
              resource: this.$t('common.resource.device.singular')
            })
          })

          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.device.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    async create() {
      try {
        this.loading = true
        const payload = {
          ...this.form
        }
        const { data = {} } = await th
          .devices()
          .create(makeHandleableBody(payload, this.form))

        if (data.id) {
          this.$message({
            type: 'success',
            message: this.$t('common.success.action.create.single', {
              resource: this.$t('common.resource.device.singular')
            })
          })

          this.$router.push({
            name: 'resources-devices-edit',
            params: { id: data.id }
          })
        }
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.create.single', {
            resource: this.$t('common.resource.device.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },

    handleUploaded(data) {
      this.runtime_url = data?.original
    },

    setContentTemplate(template) {
      if (template) {
        this.form.contents = template.contents
      }
    }
  }
}
</script>
